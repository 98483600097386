.document-container {
  display: flex;
  flex-direction: column;
  min-width: calc(100vw - 220px);
  padding: 0px;
}

.document-content {
  padding: 0px;
  min-height: calc(100vh - 38px);
  max-height: 500px;
}

.scrollable-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.not-scrollable-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
}

.scroll-icons {
  cursor: pointer;
  color: black;
}

.scroll-icons svg {
  font-size: 20px;
}

.tabs-header {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  min-width: 96%;
  max-width: 96%;
  max-height: 36px;
  justify-content: flex-start;
  align-items: center;
}

.tabs-header::-webkit-scrollbar {
  display: none;
}

.tab-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 126px;
  max-width: 126px;
  min-height: 30px;
  max-height: 30px;
  font-size: 0.8rem;
  padding: 5px 7px;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
  background-color: #d8d8e4df;
  color: #000000ad;
  border-radius: 4px;
  /* font-weight: 700; */
}

.tab-title:hover {
  background-color: rgb(255, 255, 255);
  color: black;
}

.active-tab-title {
  background-color: rgb(255, 255, 255);
  color: black;
}

.tab-title .tab-close-icon {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 20px;
  color: rgb(71, 71, 71);
}

.tab-title .tab-close-icon:hover {
  background-color: rgba(212, 212, 212, 0.7);
  border-radius: 18px;
}

.tab-title:first-of-type {
  justify-content: center;
}

#select-file-option {
  margin-top: -10px;
}

#select-category-filter {
  margin-top: -10px;
}
